<template>
  <div id="user-list">
    <!-- app drawer -->
    <v-dialog
      v-model="isFormActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
    >
      <AdresseForm
        v-model="item"
        :is-form-active="isFormActive"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{
            item.id ? $t('Modifier') : $t('Ajouter une adresse')
          }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormActive = !isFormActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template #form-cancel>
          <v-btn
            :loading="loading"
            color="secondary"
            outlined
            type="reset"
            @click="isFormActive = !isFormActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </AdresseForm>
    </v-dialog>
    <!-- country total card -->

    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t("Cartographie") }}

        <v-chip
          outlined
          class="ml-2"
        >
          {{ (dataType == null || dataType == 'all')?(parseInt(totalItems)+parseInt(totalItemsVentes)):((dataType === 'auteurs'?totalItems:totalItemsVentes)) }}
        </v-chip>
        <v-spacer></v-spacer>

<!--        <v-text-field
          v-model="distance"
          type="number"
          label="Distance"
          outlined
          dense
          clearable
          hide-details
          style="max-width: 200px"
          @change="distanceChanged"
        />
        <place-picker
          v-model="searchQuery"
          label="Addresse"
          :address="searchQuery"
          @changed="addressChanged"
        />-->
        <export-excel
          v-if="$can('download','Event') && (['all','auteurs'].includes(dataType) || dataType == null)"
          title="Export Auteur"
          :options="downloadObject"
          @refetch-data="fetchItems"
        />
        <export-excel

          v-if="$can('download','Event') && (['all','ventes'].includes(dataType) || dataType == null)"
          title="Export Ventes"
          :options="downloadObjectAcheteur"
          @refetch-data="fetchItemsAcheteur"
        />
        <!--        <v-btn
          v-if="$can('edit','Event')"
          color="primary"
          class=" me-3"
          @click.stop="openDialog(false)"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>{{ $t("Ajouter une adresse") }}</span>
        </v-btn>-->
      </v-card-title>
      <v-row>
        <v-col cols="12" md="3" lg="3">
          <GmapAutocomplete
            @place_changed="addressChanged"
            @query_changed="queryChanged"
          >
            <template v-slot:default="slotProps">
              <v-text-field v-model="address" outlined
                             dense
                             clearable
                             hide-details
                            :prepend-inner-icon="icons.mdiMagnify"
                            placeholder="Tapez votre adresse ici..."
                            ref="input"
                            v-on:listeners="slotProps.listeners"
                            v-on:attrs="slotProps.attrs">
              </v-text-field>
            </template>
          </GmapAutocomplete>
        </v-col>
        <v-col cols="12" md="2" lg="2">
<!--          <place-picker
            v-model="searchQuery"
            label="Addresse"
            :address="searchQuery"
            @changed="addressChanged"
          />-->
          <v-autocomplete
            v-model="textQuality"
            label="Qualité"
            outlined
            dense
            clearable
            hide-details
            multiple
            small-chips
            deletable-chips
            :items="$store.state.app.textQualities"
            item-text="title"
            item-value="id"
          />
        </v-col>
        <v-col cols="12" md="2" lg="2">
          <v-select
            v-model="dataType"
            label="Type"
            outlined
            dense
            clearable
            hide-details
            small-chips
            deletable-chips
            :items="[{id: 'auteurs',title: 'Auteurs'},{id: 'ventes',title: 'Ventes'},{id: 'all',title: 'Tous'}]"
            item-text="title"
            item-value="id"

          />
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <v-slider  v-model="distance"
                     type="number"
                     label="Rayon"
                     outlined
                     dense
                     clearable
                     hide-details
                     thumb-label="always"
                     :min="0"
                     :max="250"
          >
<!--            <template v-slot:append>
              <v-text-field
                v-model="distance"
                type="number"
                outlined
                dense
                clearable
                hide-details
                style="max-width: 150px"
              />
            </template>-->
          </v-slider>

        </v-col>
        <v-col cols="12" md="2">
          <v-btn :loading="loading" :disabled="address == null || !address.toString().trim().length || latitude == null || distance === 0" color="primary" @click="distanceChanged">Rechercher</v-btn>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-tabs
        v-model="tabs"
        show-arrows
        class="event-tabs"
      >
        <v-tab
          value="map"
          href="#map"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ icons.mdiMap }}
          </v-icon>
          <span>{{ $t("Carte") }}</span>
        </v-tab>
        <v-tab
          v-if="['all','auteurs'].includes(dataType) || dataType == null"
          value="table"
          href="#table"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ icons.mdiTable }}
          </v-icon>
          <span>{{ $t("Auteurs") }}</span>
        </v-tab>
        <v-tab
          v-if="['all','ventes'].includes(dataType) || dataType == null"
          value="table-vente"
          href="#table-vente"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ icons.mdiTable }}
          </v-icon>
          <span>{{ $t("Ventes") }}</span>
        </v-tab>

      </v-tabs>
      <v-tabs-items
        id="event-tabs-content"
        v-model="tabs"
        class="mt-5 pa-1"
      >
        <v-tab-item
          key="map"
          value="map"
        >
<!--          <GmapMap
            ref="mapRef"
            :center="center"
            :zoom.sync="zoom"
            map-type-id="terrain"
            :options="{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: true,
              disableDefaultUi: false,
              autocompleteControl: true,
              autocomplete: true,
            }"
            style="width: 100%; height: 700px"
          >
            <GmapMarker
              v-for="(m, index) in markers"
              :key="index"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              :shape="m.shape"

            />
          </GmapMap>-->
<!-- @click="center=m.position"-->
<authors-map :points="markers" :bounds="bounds" :center="center" @changed="addressChanged" />
        </v-tab-item>
        <v-tab-item
          v-if="['all','auteurs'].includes(dataType) || dataType == null"
          key="table"
          value="table"
        >
          <v-card flat>
            <v-data-table
              v-model="selectedRows"
              class="fixed-table"
              fixed-header
              :height="tableHeight"
              dense
              :headers="filters"
              :items="items"
              :options.sync="options"
              :page.sync="page"
              :server-items-length="totalItems"
              :loading="loading"
              :loading-text="$t('Chargement des éléments')"
              :no-data-text="$t('Pas de données')"
              :no-results-text="$t('Aucun résultat')"
              hide-default-footer
              :show-select="false"
              @page-count="pageCount = $event"
            >
              <template #body.prepend="{ items, headers }">
                <header-filters :headers="filters" />
              </template>
              <!-- name -->
              <template #[`item.author.full_name`]="{item}">
                <div class="d-flex align-center">
                  <v-avatar
                    :color="item.author.avatar ? '' : 'primary'"
                    :class="item.author.avatar ? '' : 'v-avatar-light-bg primary--text'"
                    size="32"
                  >
                    <v-img
                      v-if="item.author.avatar"
                      :lazy-src="require(`@/assets/images/avatars/2.png`)"
                      :src="item.author.avatar || require(`@/assets/images/avatars/2.png`)"
                    ></v-img>
                    <span
                      v-else
                      class="font-weight-medium"
                    >{{ avatarText(item.author.full_name) }}</span>
                  </v-avatar>

                  <div class="d-flex flex-column ms-3">
                    <router-link
                      :to="{ name : 'author-view', params : { id : item.author_id } }"
                      class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                    >
                      {{ item.author.full_name }}
                    </router-link>
                    <!--              <small>@{{ item.authorname }}</small>-->
                  </div>
                </div>
              </template>

              <template #[`item.author.phone`]="{item}">
                {{ formatPhoneNumber(item.author) }}
              </template>
              <template #[`item.author.email`]="{item}">
                {{ item.author.email ? item.author.email.join(', ') : '' }}
              </template>

              <template #[`item.old_author`]="{item}">
                <div class="text-center">
                  <v-icon
                    v-if="item.old_author"
                    small
                    color="success"
                  >
                    {{ icons.mdiCheckCircle }}
                  </v-icon>
                </div>
              </template>
              <template #[`item.minor_author`]="{item}">
                <div class="text-center">
                  <v-icon
                    v-if="item.minor_author"
                    small
                    color="success"
                  >
                    {{ icons.mdiCheckCircle }}
                  </v-icon>
                </div>
              </template>
              <template #[`item.particular_case`]="{item}">
                <div class="text-center">
                  <v-icon
                    v-if="item.particular_case === 'yes'"
                    small
                    color="success"
                  >
                    {{ icons.mdiCheckCircle }}
                  </v-icon>
                </div>
              </template>
              <template #[`item.publish`]="{item}">
                <div
                  v-if="item.publish"
                  class="text-center"
                >
                  <!--            <router-link :to="{ name : 'assistance_publication', query : { authorEmail: item.email } }" >-->
                  <v-icon
                    small
                    color="success"
                  >
                    {{ icons.mdiCheckCircle }}
                  </v-icon>
                  <!--            </router-link>-->
                </div>
              </template>

              <!-- actions -->
              <template #[`item.actions`]="{item}">
                <v-menu
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-if="$can('edit','Event')"
                      @click="openDialog(item)"
                    >
                      <v-list-item-title>
                        <v-icon
                          size="20"
                          class="me-2"
                        >
                          {{ icons.mdiPencilOutline }}
                        </v-icon>
                        <span>{{ $t('Modifier') }}</span>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="$can('delete','Event')"
                      @click="confirmDelete(item)"
                    >
                      <v-list-item-title>
                        <v-icon
                          size="20"
                          class="me-2"
                        >
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                        <span><span>{{ $t('Supprimer') }}</span></span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
            <v-card-text class="pt-2">
              <v-row>
                <v-col
                  lg="2"
                  cols="3"
                >
                  <rows-per-page :options="options" />
                </v-col>

                <v-col
                  lg="10"
                  cols="9"
                  class="d-flex justify-end"
                >
                  <v-pagination
                    v-model="page"
                    total-visible="6"
                    :length="pageCount"
                  ></v-pagination>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item
          v-if="['all','ventes'].includes(dataType) || dataType == null"
          key="table-vente"
          value="table-vente"
        >
          <v-card flat>
            <v-data-table
              v-model="selectedRows"
              class="fixed-table"
              fixed-header
              :height="tableHeight"
              dense
              :headers="filtersVentes"
              :items="itemsAcheteur"
              :options.sync="optionsAcheteur"
              :page.sync="pageVente"
              :server-items-length="totalItemsVentes"
              :loading="loadingVente"
              :loading-text="$t('Chargement des éléments')"
              :no-data-text="$t('Pas de données')"
              :no-results-text="$t('Aucun résultat')"
              hide-default-footer
              :show-select="false"
              @page-count="pageCountVente = $event"
            >
              <template #body.prepend="{ items, headers }">
                <header-filters :headers="filtersVentes" />
              </template>
              <!-- name -->
              <template #[`item.author.full_name`]="{item}">
                <div class="d-flex align-center">
                  <v-avatar
                    :color="item.author.avatar ? '' : 'primary'"
                    :class="item.author.avatar ? '' : 'v-avatar-light-bg primary--text'"
                    size="32"
                  >
                    <v-img
                      v-if="item.author.avatar"
                      :lazy-src="require(`@/assets/images/avatars/2.png`)"
                      :src="item.author.avatar || require(`@/assets/images/avatars/2.png`)"
                    ></v-img>
                    <span
                      v-else
                      class="font-weight-medium"
                    >{{ avatarText(item.author.full_name) }}</span>
                  </v-avatar>

                  <div class="d-flex flex-column ms-3">
                    <router-link
                      :to="{ name : 'author-view', params : { id : item.author_id } }"
                      class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                    >
                      {{ item.author.full_name }}
                    </router-link>
                    <!--              <small>@{{ item.authorname }}</small>-->
                  </div>
                </div>
              </template>

              <template #[`item.author.phone`]="{item}">
                {{ formatPhoneNumber(item.author) }}
              </template>
              <template #[`item.author.email`]="{item}">
                {{ item.author.email ? item.author.email.join(', ') : '' }}
              </template>

              <template #[`item.old_author`]="{item}">
                <div class="text-center">
                  <v-icon
                    v-if="item.old_author"
                    small
                    color="success"
                  >
                    {{ icons.mdiCheckCircle }}
                  </v-icon>
                </div>
              </template>
              <template #[`item.minor_author`]="{item}">
                <div class="text-center">
                  <v-icon
                    v-if="item.minor_author"
                    small
                    color="success"
                  >
                    {{ icons.mdiCheckCircle }}
                  </v-icon>
                </div>
              </template>
              <template #[`item.particular_case`]="{item}">
                <div class="text-center">
                  <v-icon
                    v-if="item.particular_case === 'yes'"
                    small
                    color="success"
                  >
                    {{ icons.mdiCheckCircle }}
                  </v-icon>
                </div>
              </template>
              <template #[`item.publish`]="{item}">
                <div
                  v-if="item.publish"
                  class="text-center"
                >
                  <!--            <router-link :to="{ name : 'assistance_publication', query : { authorEmail: item.email } }" >-->
                  <v-icon
                    small
                    color="success"
                  >
                    {{ icons.mdiCheckCircle }}
                  </v-icon>
                  <!--            </router-link>-->
                </div>
              </template>

              <!-- actions -->
              <template #[`item.actions`]="{item}">
                <v-menu
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-if="$can('edit','Event')"
                      @click="openDialog(item)"
                    >
                      <v-list-item-title>
                        <v-icon
                          size="20"
                          class="me-2"
                        >
                          {{ icons.mdiPencilOutline }}
                        </v-icon>
                        <span>{{ $t('Modifier') }}</span>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="$can('delete','Event')"
                      @click="confirmDelete(item)"
                    >
                      <v-list-item-title>
                        <v-icon
                          size="20"
                          class="me-2"
                        >
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                        <span><span>{{ $t('Supprimer') }}</span></span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
            <v-card-text class="pt-2">
              <v-row>
                <v-col
                  lg="2"
                  cols="3"
                >
                  <rows-per-page :options="optionsAcheteur" />
                </v-col>

                <v-col
                  lg="10"
                  cols="9"
                  class="d-flex justify-end"
                >
                  <v-pagination
                    v-model="pageVente"
                    total-visible="6"
                    :length="pageCountVente"
                  ></v-pagination>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>

      </v-tabs-items>
    </v-card>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import Vue from 'vue'

/* import * as VueGoogleMaps from 'vue2-google-maps' */
import GmapVue from 'gmap-vue'
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline, mdiCheckCircle,
  mdiMap,
  mdiTable,
} from '@mdi/js'
import { onUnmounted, ref, watch } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import axios from '@axios'
import { icon } from 'leaflet'
import store from '@/store'
import AdresseForm from './EventForm.vue'
import userStoreModule from './itemStoreModule'
import authorStoreModule from '../author/authorStoreModule'

import Controller from './Controller'
import RowsPerPage from '@/components/RowsPerPage'
import HeaderFilters from '@/components/HeaderFilters'
import ExportExcel from '@/components/ExportExcel'
import AuthorsMap from "@/views/apps/cartographies/AuthorsMap.vue";

/*import AuthorsMap from '@/views/apps/cartographies/AuthorsMap.vue'
import PlacePicker from '@/components/PlacePicker.vue'*/

window.MyCustomCallback = () => { console.info('MyCustomCallback was executed') }
Vue.use(GmapVue, {
  load: {
    // [REQUIRED] This is the unique required value by Google Maps API
    key: 'AIzaSyC8_8YmWMNDqCSHzFI9A_7Xg5Miugh6rqo',
    language: 'fr',

    // [OPTIONAL] This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    libraries: 'places',

    // [OPTIONAL] If you want to set the version, you can do so:
    v: '3.26',

    // This option was added on v3.0.0 but will be removed in the next major release.
    // If you already have an script tag that loads Google Maps API and you want to use it set you callback
    // here and our callback `GoogleMapsCallback` will execute your custom callback at the end; it must attached
    // to the `window` object, is the only requirement.
    customCallback: 'MyCustomCallback',
  },

  // [OPTIONAL] If you intend to programmatically custom event listener code
  // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  // you might need to turn this on.
  autoBindAllEvents: false,

  // [OPTIONAL] If you want to manually install components, e.g.
  // import {GmapMarker} from 'gmap-vue/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)
  // then set installComponents to 'false'.
  // If you want to automatically install all the components this property must be set to 'true':
  installComponents: true,

  // Load the Google Maps API dynamically, if you set this to `true` the plugin doesn't load the Google Maps API
  dynamicLoad: false,
})
export default {
  components: {
    AuthorsMap,
   /* PlacePicker,*/
   /* AuthorsMap,*/

    ExportExcel,
    HeaderFilters,
    RowsPerPage,
    AdresseForm,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'event'
    const APP_STORE_MODULE_NAME_AUTHOR = 'app-author'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME_AUTHOR)) store.registerModule(APP_STORE_MODULE_NAME_AUTHOR, authorStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME_AUTHOR)) store.unregisterModule(APP_STORE_MODULE_NAME_AUTHOR)
    })

    const {
      downloadObject,
      downloadObjectAcheteur,
      item,
      items,
      itemsAcheteur,
      markers,
      bounds,
      tableColumns,
      tableColumnsAcheteur,
      filters,
      filtersVentes,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      totalItems,
      totalItemsVentes,
      loading,
      loadingVente,
      options,
      optionsAcheteur,
      authorTotalLocal,
      authorTotalLocalVentes,
      selectedRows,
      isFormActive,
      snackBarMessage,
      isSnackbarBottomVisible,
      openDialog,
      deleteItem,
      confirmDelete,
      deleteDialog,
      fetchItems,
      fetchItemsAcheteur,
      resolveAuthorRoleVariant,
      resolveAuthorRoleIcon,
      resolveAuthorStatusVariant,
      resolveAuthorStatusName,
      resolveAuthorTotalIcon,
      fetchItemsEvents,
      addressChanged,
      queryChanged,
      address,
      distanceChanged,
      distance,
      textQuality,
      dataType,
      latitude,
      longitude,
      center,
      zoom,
    } = Controller()

    const superiors = ref([])
    const tabs = ref([])

    const payOptions = [
      { title: 'Admin', value: 'admin' },
      { title: 'Author', value: 'author' },
      { title: 'Editor', value: 'editor' },
      { title: 'Maintainer', value: 'maintainer' },
      { title: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { title: 'Basic', value: 'basic' },
      { title: 'Company', value: 'company' },
      { title: 'Enterprise', value: 'enterprise' },
      { title: 'Standard', value: 'standard' },
    ]

    const statusOptions = [
      { title: 'Actif', value: 1 },
      { title: 'Inactif', value: 0 },
    ]
    filters.value = tableColumns
    filtersVentes.value = tableColumnsAcheteur

    // store.dispatch('app-author/searchAuthors', { term: '', rowsPerPage: 100 }).then(response => {
    //   superiors.value = response.data
    // })
    store
      .dispatch('app-author/fetchCountries', { per_page: 500 })

    const formatPhoneNumber = val => {
      if (val.country_id == (store.state.app.settings.default_country_id || 79)) {
        // let p = val.phone
        // var string = "02076861111"
        let string = val.phone
        if (string && string.length < 9) {
          string = `0${string}`
        }
        if (string) {
          if (string.length > 7 && string.length < 10) {
            const phone = string.replace(/(\d{2})(\d{2})(\d{2})(\d{2})/, '$1.$2.$3.$4')

            return phone
          }
          if (string.length > 9) {
            const phone = string.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1.$2.$3.$4.$5')

            return phone
          }

          const phone = string.replace(/(\d{2})(\d{2})(\d{7})/, '$1.$2.$3')

          return phone
        }

        return val.phone
      }

      return val.phone
    }

    const windowHeight = ref(window.innerHeight)
    const tableHeight = ref(window.innerHeight - 250)
    window.addEventListener('resize', () => {
      windowHeight.value = window.innerHeight
    })
    watch(windowHeight, () => {
      tableHeight.value = windowHeight.value - 250
    })

    return {
      tabs,
      tableHeight,
      item,
      items,
      itemsAcheteur,
      markers,
      bounds,
      superiors,
      tableColumns,
      tableColumnsAcheteur,
      filters,
      filtersVentes,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      totalItems,
      totalItemsVentes,
      payOptions,
      planOptions,
      statusOptions,
      loading,
      loadingVente,
      options,
      optionsAcheteur,
      authorTotalLocal,
      authorTotalLocalVentes,
      isFormActive,
      selectedRows,
      snackBarMessage,
      isSnackbarBottomVisible,
      page: 1,
      pageVente: 1,
      pageCount: 0,
      pageCountVente: 0,
      itemsPerPage: 10,
      openDialog,
      deleteItem,
      confirmDelete,
      deleteDialog,
      downloadObject,
      downloadObjectAcheteur,
      avatarText,
      resolveAuthorRoleVariant,
      resolveAuthorRoleIcon,
      resolveAuthorStatusVariant,
      resolveAuthorStatusName,
      resolveAuthorTotalIcon,
      fetchItems,
      fetchItemsAcheteur,
      formatPhoneNumber,
      fetchItemsEvents,
      addressChanged,
      queryChanged,
      address,
      distanceChanged,
      distance,
      textQuality,
      dataType,
      latitude,
      longitude,
      center,
      zoom,
      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
        mdiCheckCircle,
        mdiMap,
        mdiTable,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.autocomplete-container {
  align-items: flex-start;
 /* position: absolute;
  top: 20px;
  left: 20px;*/
  width: 100%;
  max-width: 100%;
position: relative;
  z-index: 1000004;
  background: white;
  /*padding: 3px 8px 5px;*/
  /*border-radius: 9px;*/
  padding-top: 8px;
  padding-bottom: 8px;
  height: 40px;
  border: 1px solid #dcdade;
  input  {
    outline: unset;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: auto;
    flex-grow: 1;
    flex-wrap: wrap;
    min-width: 0;
    width: 100%;
    font-size: 16px;
    padding-left: 8px;


  }
}
</style>
